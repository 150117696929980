import React, { useState, useEffect } from "react";
import qooperLogo from "../../assets/images/qooper.png";
import OrganizationImage from "../Organizations/Organization/Image";

const OrganizationNavItem = (props) => (
  <div className="org-logo">
    <OrganizationImage
      large
      src={props.homePage.logo_url || qooperLogo}
      alt="Organization Logo"
    />
  </div>
);

const NavItem = (props) => {
  const titleStyle = {
    fontSize: "16px",
    color: props.item.is_active ? props.color : "inherit",
  };

  const handleClick = (event) => {
    props.setIsOpen(false);
    props.clickHandler(event, props.item);
  };

  return (
    <li>
      <a
        href="javascript:void(0);"
        onClick={handleClick}
        className={`nav-link ${props.item.is_active ? "active" : ""}`}
        style={{
          "--active-color": props.color,
        }}
      >
        <span style={titleStyle}>{props.item.navigation_title}</span>
      </a>
    </li>
  );
};

function Toolbar(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.getElementById("navbar-filter");
      const toggleButton = document.querySelector(".navbar-toggle");

      if (
        isOpen &&
        navbar &&
        !navbar.contains(event.target) &&
        !toggleButton.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Prevent body scroll when navbar is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="custom-toolbar">
      <div
        className={`navbar-overlay ${isOpen ? "show" : ""}`}
        onClick={() => setIsOpen(false)}
      />
      <nav
        id="navbar"
        className="navbar navbar-default navbar-component navbar-fixed-top custom-navbar"
      >
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              onClick={toggle}
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <OrganizationNavItem homePage={props.homePage} />
          </div>

          <div
            className={`collapse navbar-collapse ${isOpen ? "in" : ""}`}
            id="navbar-filter"
          >
            <ul className="nav navbar-nav">
              {props.navigationItems.map((item) => {
                return (
                  <NavItem
                    key={`page-${item.id}`}
                    item={item}
                    color={props.homePage.organization.color}
                    clickHandler={props.navigationItemClickHandler}
                    setIsOpen={setIsOpen}
                  />
                );
              })}
            </ul>

            <ul className="nav navbar-nav navbar-right">
              <li>{props.rightButton}</li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

// NOTE(cenk.yurtbilir): Below is removed from NavigationToolbar because of the the request of Customer Success Dept. based on previous requests and COX request

// rightButton={
//   <Button
//     action={this.openJoinModalHandler}
//     style={{
//       color: 'white',
//       backgroundColor: this.state.homePage.organization.color,
//       margin: '15px',
//       fontSize: '18px'
//     }}>
//     JOIN
//   </Button>
// }

export default Toolbar;

import React, { Component } from "react";
import { apiService, securityService } from "../../services";

import qooperLogo from "../../assets/images/qooper.png";

import Aux from "../../hoc/Aux";
import Spinner from "../UI/Spinner";
import Page from "../Pages/Page";
import NavigationToolbar from "../Navigation/Toolbar";
import Modal from "../UI/Modal";
import JoinContent from "./Join/Content";

class Pages extends Component {
  state = {
    pagesSelectedTabId: "home",
    homePage: null,
    homePageError: null,
    navigationItems: null,
    showJoinModal: false,
  };

  componentDidMount() {
    this.getHomePage();
  }

  getOrganizationIdFromRoute(match) {
    const encodedOrganizationIdFromRoute = match.params.encoded_organization_id;
    if (!encodedOrganizationIdFromRoute) {
      return null;
    }

    return securityService.decodeId(
      encodedOrganizationIdFromRoute.replace(/s+|\n/g, "")
    );
  }

  getProgramIdFromRoute(match) {
    const encodedProgramIdFromRoute = match.params.encoded_program_id;
    if (!encodedProgramIdFromRoute) {
      return null;
    }

    return securityService.decodeId(
      encodedProgramIdFromRoute.replace(/s+|\n/g, "")
    );
  }

  getHomePage = () => {
    const { match } = this.props;

    const organizationId = this.getOrganizationIdFromRoute(match);
    const programId = this.getProgramIdFromRoute(match);

    if (organizationId) {
      apiService.getHomePage(
        organizationId,
        programId,
        (res) => {
          let navigationItems = [
            {
              id: "home",
              title: "Home",
              navigation_title: "Home",
              content: res.data,
              is_active: true,
            },
          ];
          const otherPagesNavigationItems = res.data.promo_website_pages.map(
            (page) => {
              page.is_active = false;
              return page;
            }
          );
          navigationItems = [...navigationItems, ...otherPagesNavigationItems];
          this.setState({
            homePage: res.data,
            navigationItems: navigationItems,
          });
          document.title = res.data.home_page_promo_title;
          document
            .querySelector("head")
            .querySelector('link[rel="shortcut icon"]').href =
            res.data.home_page_logo_url || qooperLogo;
        },
        (err) => {
          this.props.history.push("/not-found");
          document.title = "Not Found";
          this.setState({ homePageError: err });
        }
      );
    } else {
      this.props.history.push("/not-found");
      document.title = "Not Found";
    }
  };

  navigationItemClickHandler = (event, item) => {
    event.preventDefault();
    const updatedNavigationItems = [...this.state.navigationItems];
    const activeNavigationItemIndex = updatedNavigationItems.findIndex(
      (navItem) => navItem.is_active
    );
    updatedNavigationItems[activeNavigationItemIndex].is_active = false;
    const navigationIndexToActivate = updatedNavigationItems.findIndex(
      (navItem) => navItem.id === item.id
    );
    updatedNavigationItems[navigationIndexToActivate].is_active = true;
    this.setState({
      navigationItems: updatedNavigationItems,
    });
  };

  openJoinModalHandler = () => {
    this.setState({
      showJoinModal: true,
    });
  };

  closeModalHandler = () => {
    this.setState({
      showJoinModal: false,
    });
  };

  // Render
  render() {
    let content = <Spinner md styles={{ margin: "25% auto" }} />;
    if (this.state.homePage) {
      if (
        this.state.navigationItems &&
        this.state.navigationItems.length <= 1
      ) {
        content = (
          <Page
            page={{
              id: "home",
              title: "Home",
              navigation_title: "Home",
              content: this.state.homePage,
              is_active: true,
            }}
          />
        );
      } else {
        const activePage = this.state.navigationItems.find(
          (item) => item.is_active
        );
        content = (
          <Aux>
            <NavigationToolbar
              homePage={this.state.homePage}
              navigationItems={this.state.navigationItems}
              navigationItemClickHandler={this.navigationItemClickHandler}
            />
            <Page
              page={activePage}
              color={this.state.homePage.organization_color}
            />
            {/* Join Modal */}
            {this.state.showJoinModal && (
              <Modal
                full
                show={this.state.showJoinModal}
                close={this.closeModalHandler}
              >
                <JoinContent dark homePage={this.state.homePage} />
              </Modal>
            )}
          </Aux>
        );
      }
    }
    return content;
  }
}

export default Pages;

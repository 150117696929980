import React from "react";
import Radium from "radium";

const PageContainer = ({ bg, isHomePage, children }) => {
  return (
    <div
      id="page-container"
      className="page-container"
      style={[
        {
          position: "relative",
          backgroundColor: "#F7F7F7",
          height: "100%",
        },
      ]}
    >
      {isHomePage && (
        <>
          <div id="bg">
            <img src={bg} alt="Background Image" />
          </div>
          <div className="layer"></div>
        </>
      )}
      <div className="page-content">{children}</div>
    </div>
  );
};

export default Radium(PageContainer);

import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

import PageContainer from "../../containers/PageContainer";
import MainContent from "../../containers/MainContent/MainContent";
import Content from "../../containers/MainContent/Content";
import HomePage from "./HomePage";
import PoweredBy from "./PoweredBy";

import Aux from "../../hoc/Aux";

import backgroundImage from "../../assets/images/bg.jpg";

const Page = (props) => {
  const isHomePage = props.page.id === "home";

  useEffect(() => {
    const navbar = document.getElementById("navbar");
    const pageContainer = document.getElementById("page-container");
    const content = document.getElementById("content");

    if (navbar && pageContainer && content) {
      pageContainer.style.marginTop = `${navbar.offsetHeight}px`;
      content.style.height = `calc(100% - ${navbar.offsetHeight}px)`;
    }
  }, []);

  return (
    <Aux>
      <PageContainer
        isHomePage={isHomePage}
        bg={
          isHomePage && (props.page.content.home_page_bg_url || backgroundImage)
        }
      >
        <MainContent>
          <Content>
            {isHomePage ? (
              <HomePage homePage={props.page.content} />
            ) : (
              <Aux>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      {ReactHtmlParser(props.page.content)}
                    </div>
                  </div>
                </div>
              </Aux>
            )}
            <PoweredBy homePage={props.homePage} />
          </Content>
        </MainContent>
      </PageContainer>
    </Aux>
  );
};

export default Page;
